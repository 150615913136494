import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useStyle from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {
    Container,
    Grid,
    MenuItem,
    Dialog,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import Paragraph from "../../components/Typography/Paragraph";
import maintaincewhite from "../../assets/images/maintaincewhite.svg";
import maintainceorange from "../../assets/images/maintainceorange.svg";
import womansmile from "../../assets/images/womansmile.jpg";
import vectorbg from "../../assets/images/vectorbg.png";
import line from "../../assets/images/line.svg";
import team from "../../assets/images/team.svg";
import target from "../../assets/images/target.svg";
import serviceimage from "../../assets/images/serviceimage.jpg";
import yearofbusiness from "../../assets/images/yearofbusiness.svg";
import satisfyclient from "../../assets/images/satisfyclient.svg";
import dealcase from "../../assets/images/dealcase.svg";
import approvalrate from "../../assets/images/approvalrate.svg";
import step1 from "../../assets/images/step1.svg";
import step2 from "../../assets/images/step2.svg";
import step3 from "../../assets/images/step3.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import "./styles.css"
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import Step1 from "../../assets/images/Step1.png";
import Step2 from "../../assets/images/Step2.png";
import Step3 from "../../assets/images/Step3.png";
import Step4 from "../../assets/images/Step4.png";
import Step5 from "../../assets/images/Step5.png";
import Step6 from "../../assets/images/Step6.png";
import ArrowModal from "../../assets/images/arrow.svg";
import interview from "../../assets/images/interview.jpg"
import quality from "../../assets/images/quality.jpg"



const Home = () => {

    const classes = useStyle();
    const theme = useTheme();
    const { t } = useTranslation();

    const isMobile = window.innerWidth < 800;

    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = React.useState("paper");
    const [modalState, setModalState] = React.useState("app");

    const descriptionElementRef = React.useRef(null);

    const handleClickOpen = (e) => {
        setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
        setModalState("app")
    };

    const handleDownloadApk = () => {
        // window.location.href = "http://hedgecapital.group/hform.apk"
        setModalState("tutorial")
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <React.Fragment>
            <Navbar />
            <div style={{ overFlow: "hidden" }}>
                <div className={classes.theBannerBG}>

                    <Container maxWidth="sm">
                        <div className={classes.theTitleOne}>
                            <div className={classes.theContent}>
                                {/* <Paragraph center size="48px" bold="500">
                                    HUI XIN<br />
                                    HOLDINGS <br />
                                </Paragraph> */}

                                <div className={classes.theWordMargin}>
                                    <h1 contenteditable>
                                        HUIXIN<br />
                                        HOLDINGS <br />
                                    </h1>
                                </div>

                                {/* <div style={{ marginBottom: "3rem" }} />

                                <div className={classes.theApplyBTN}
                                    onClick={() => window.location.replace("#blog")}
                                >
                                    <div>
                                        <img src={maintaincewhite} />
                                    </div>
                                    <Paragraph color="#FFFFFF" size="14px" bold="500" margin="0 0 0 0.5rem">
                                        Application
                                    </Paragraph>
                                </div> */}
                            </div>
                        </div>
                    </Container>


                    <Container maxWidth="md">
                        <div className={classes.theMaintainanceContent}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <div className={classes.theBorderRight}>
                                        <img src={maintainceorange} />
                                        <Paragraph color="#282828" size="18px" bold="600" margin="0.5rem 0 0 0">
                                            Internal Sourcing
                                        </Paragraph>
                                        <Paragraph color="#000000" size="12px" bold="400" margin="0.5rem 0 0 0">
                                            <b>Employee referrals:</b>
                                            <br />
                                            Encouraging current employees to refer candidates
                                            <br />
                                            <b>Internal job postings:</b>
                                            <br />
                                            Advertising job openings within the company

                                        </Paragraph>
                                    </div>

                                </Grid>


                                <Grid item xs={12} sm={12} md={4}>
                                    <div className={classes.theBorderRight}>
                                        <img src={maintainceorange} />
                                        <Paragraph color="#282828" size="18px" bold="600" margin="0.5rem 0 0 0">
                                            External Sourcing
                                        </Paragraph>
                                        <Paragraph color="#000000" size="12px" bold="400" margin="0.5rem 0 0 0">
                                            <b>Job boards:</b>
                                            <br />
                                            Posting vacancies on popular job search platforms

                                        </Paragraph>
                                    </div>

                                </Grid>


                                <Grid item xs={12} sm={12} md={4}>
                                    <div>
                                        <img src={maintainceorange} />
                                        <Paragraph color="#282828" size="18px" bold="600" margin="0.5rem 0 0 0">
                                            Recruitment Agencies
                                        </Paragraph>
                                        <Paragraph color="#000000" size="12px" bold="400" margin="0.5rem 0 0 0">
                                            <b>Engaging external agencies:</b>
                                            <br />
                                            To assist with talent acquisition when specific expertise is required

                                        </Paragraph>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>


                <div className={classes.theMarginBottom} />

                <div className={classes.theBannerBGTwo}>
                    <Container maxWidth="md">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6} >
                                <div className={classes.theBannerTwoPosition}>
                                    <div>
                                        <img style={{ width: "75%" }} src={womansmile} />
                                    </div>
                                    <div className={classes.theWordPosition}>
                                        <div>
                                            <Paragraph color="#fff" size="18px" bold="600">
                                                Job Analysis and Description
                                            </Paragraph>
                                            <div>
                                                <Paragraph color="#fff" size="12px" bold="400" margin="0.5rem 0 0 0">
                                                    Accurate job analysis is crucial for effective recruitment.
                                                </Paragraph>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} >
                                <div className={classes.thePaddingHome} id="about">
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Paragraph color="#d6bf84" size="18px" bold="600" margin="0 0.75rem 0 0">
                                            About Us
                                        </Paragraph>
                                        <div>
                                            <img src={line} />
                                        </div>
                                    </div>

                                    <div style={{ marginBottom: "2rem" }} />
                                    {isMobile ?
                                        (
                                            <>
                                                <Paragraph color="#000000" size="18px" bold="bold">
                                                    Our Recruitment and Selection Policy sets the guidelines for attracting and selecting top talent.
                                                </Paragraph>
                                            </>
                                        ) : (
                                            <>
                                                <Paragraph color="#000000" size="26px" bold="bold">
                                                    Our Recruitment and Selection Policy sets the guidelines for attracting and selecting top talent.
                                                </Paragraph>
                                            </>
                                        )}


                                    <div style={{ marginBottom: "2rem" }} />

                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <div>
                                                <img src={team} />
                                            </div>
                                            <Paragraph color="#000000" size="18px" bold="bold">
                                                This policy aligns with our commitment to anti-racism, diversity, equity, and compliance with relevant laws and regulations.                                            </Paragraph>

                                            <div style={{ marginBottom: "1rem" }} />

                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} >
                                            <div>
                                                <img src={target} />
                                            </div>
                                            <Paragraph color="#000000" size="18px" bold="bold">
                                                It covers all stages of the hiring process, from job analysis and posting to onboarding.                                            </Paragraph>

                                            <div style={{ marginBottom: "1rem" }} />

                                        </Grid>

                                    </Grid>

                                </div>

                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <hr style={{ border: "1px solid #e7e3d9" }} />

                <div style={{ padding: "1rem 0 2rem 0" }} id="service">
                    <Container maxWidth="md">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                <img src={line} />
                            </div>
                            <Paragraph color="#d6bf84" size="18px" bold="600" margin="0 0.75rem">
                                Recruitment
                            </Paragraph>
                            <div>
                                <img src={line} />
                            </div>
                        </div>

                        <div style={{ marginBottom: "1rem" }} />

                        <Paragraph center color="#000000" size="32px" bold="600">
                            Interviews & Assessments
                        </Paragraph>

                        <div style={{ marginBottom: "1rem" }} />

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <div className={classes.theServiceContent}>
                                    <div className={classes.theServiceImg}>
                                        <img src={interview} />
                                    </div>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="18px" bold="bold">
                                        Interviews
                                    </Paragraph>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="12px" bold="400">
                                        Conducting behavioural and technical interviews to evaluate candidates' skills and competencies.
                                    </Paragraph>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={4} >
                                <div className={classes.theServiceContent}>
                                    <div className={classes.theServiceImg}>
                                        <img src={serviceimage} />
                                    </div>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="18px" bold="bold">
                                        Asssessments
                                    </Paragraph>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="12px" bold="400">
                                        Utilising assessments, tests, and practical exercises tailored to the job requirements.
                                    </Paragraph>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={4} >
                                <div className={classes.theServiceContent}>
                                    <div className={classes.theServiceImg}>
                                        <img src={quality} />
                                    </div>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="18px" bold="bold">
                                        Quality
                                    </Paragraph>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="12px" bold="400">
                                        Ensuring interview panels are diverse and represent various perspectives.
                                    </Paragraph>
                                </div>

                            </Grid>

                        </Grid>

                    </Container>
                </div>

                <div className={classes.theBannerBGThree} id="team">
                    <Container maxWidth="md">
                        <div style={{ padding: "4rem 0" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={yearofbusiness} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                5
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            Year of Business
                                        </Paragraph>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={satisfyclient} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                97%
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            Satisfy Client
                                        </Paragraph>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={dealcase} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                300+
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            Deal Case
                                        </Paragraph>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={approvalrate} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                95%
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            Approval Rate
                                        </Paragraph>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>

                <div style={{ padding: "2rem 0" }} id="blog">
                    <Container maxWidth="md">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                <img src={line} />
                            </div>
                            <Paragraph color="#d6bf84" size="18px" bold="600" margin="0 0.75rem" center>
                                Application Procedures
                            </Paragraph>
                            <div>
                                <img src={line} />
                            </div>
                        </div>

                        <div style={{ marginBottom: "2rem" }} />

                        <div>
                            <Paragraph color="#000" size="14px" bold="500" margin="0 0.75rem" center>
                                Our company is committed to conducting a fair and thorough application and screening process to identify the most qualified candidates for our open positions.

                            </Paragraph>
                        </div>

                        <div style={{ marginBottom: "2rem" }} />

                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={3} >
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 1
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph color="#000000" size="14px" bold="600">
                                                Application Submission
                                            </Paragraph>




                                        </div>
                                    </div>
                                    <br />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} >
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 2
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph color="#000000" size="14px" bold="600">
                                                Confidentiality
                                            </Paragraph>



                                        </div>
                                    </div>
                                    <br />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} >
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 3
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph color="#000000" size="14px" bold="600">
                                                Initial Screening
                                            </Paragraph>




                                        </div>
                                    </div>
                                    <br />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} >
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 4
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph color="#000000" size="14px" bold="600">
                                                Shortlisting
                                            </Paragraph>



                                        </div>
                                    </div>
                                    <br />
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{ marginBottom: "1rem" }} />

                        <div>
                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={12} md={3} >
                                    <br />
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 5
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph color="#000000" size="14px" bold="600">
                                                Interviews
                                            </Paragraph>



                                        </div>
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} md={3} >
                                    <br />
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 6
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph color="#000000" size="14px" bold="600">
                                                Evaluations
                                            </Paragraph>



                                        </div>
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} md={3} >
                                    <br />
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 7
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph center color="#000000" size="14px" bold="600">
                                                Candidate Communication
                                            </Paragraph>



                                        </div>
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} md={3} >
                                    <br />
                                    <div className={classes.theApplyBox}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Paragraph color="#000000" size="14px" bold="600">
                                                    STEP 8
                                                </Paragraph>
                                            </div>
                                            <div>
                                                <img src={step1} />
                                            </div>
                                        </div>

                                        <hr style={{ background: "#d6bf84", margin: "0.75rem 0" }} />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                            <Paragraph color="#000000" size="14px" bold="600">
                                                Record Keeping
                                            </Paragraph>



                                        </div>
                                    </div>

                                </Grid>

                            </Grid>
                        </div>


                        <div style={{ marginBottom: "5rem" }} />

                    </Container>
                </div>

                <div className={classes.theBannerBGFour}>

                    <Container maxWidth="md">
                        <div style={{ padding: "2rem 0" }}>
                            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div>
                                    <img src={line} />
                                </div>
                                <Paragraph color="#d6bf84" size="18px" bold="600" margin="0 0.75rem">
                                    FAQ's
                                </Paragraph>
                                <div>
                                    <img src={line} />
                                </div>
                            </div> */}
                            <div style={{ marginBottom: "2rem" }} />

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            Interview Panel
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            An interview panel will be formed for each position, consisting of at least two members, including a representative from the HR department and the hiring manager or relevant subject matter expert.
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            Interview Preparation
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            Panel members will review the candidate's application materials and familiarise themselves with the job description, qualifications, to assess the candidate's suitability for the position.
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            Interview Structure and Questions
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            Interviews will be structured to gather relevant information about the candidate's skills, experience, and fit for the role. All candidates will be asked the same core questions to ensure consistency and fairness.
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            Behavioural & Technical Interviews
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            The panel will assess the candidate’s behavioural and technical skills through questions, case studies, or practical exercises designed to evaluate their proficiency.
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            Assessments and Tests
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            Assessments and tests may be administered to evaluate specific skills, aptitude, or knowledge relevant to the position.
                                            The type of assessment will be determined based on the requirements of the role and the industry best practices.
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>

                            </div>

                        </div>



                    </Container>
                </div>


            </div>
            <Footer />
            <Dialog
                onClose={handleClose}
                open={open}
                scroll={scroll}
                fullWidth={true}
                maxWidth={"xl"}
            >

                <div className={classes.theModal}>
                    {modalState == "app" ?
                        <React.Fragment>
                            <Paragraph center textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0.75rem">
                                Mobile Application Apk
                            </Paragraph>
                            <div className={classes.theCloseModal} onClick={handleClose}>
                                <CloseIcon />
                            </div>

                            <div className={classes.theModalDownloadBtn} onClick={handleDownloadApk}>
                                <Paragraph center color="#FFFFFF" size="14px" bold="400">
                                    Download APP
                                </Paragraph>
                            </div>
                        </React.Fragment>
                        :

                        <React.Fragment>
                            <Paragraph
                                center
                                textTransform="uppercase"
                                color="#EA4843"
                                size="18px"
                                bold="600"
                                margin="0 0 3rem"
                            >
                                Mobile Application :User Manual
                            </Paragraph>
                            <div className={classes.theCloseModal} onClick={handleClose}>
                                <CloseIcon />
                            </div>
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step1}
                                                alt={Step1}
                                            />
                                        </div>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step2}
                                                alt={Step2}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                Step 1
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                Allow the access of Unknown Sources on your phone setting
                                            </Paragraph>
                                        </div>
                                    </div>

                                </div>
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step3}
                                                alt={Step3}
                                            />
                                        </div>
                                        <div className={classes.theArrowAbsolute}>
                                            <img
                                                src={ArrowModal}
                                                alt={ArrowModal}
                                            />
                                        </div>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step4}
                                                alt={Step4}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                Step 2
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                Open the application and Allow the access & Click “NEXT” button
                                            </Paragraph>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step5}
                                                alt={Step5}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                Step 3
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                Choose the Category
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679; For Business Man
                                            </Paragraph>
                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679; For Worker
                                            </Paragraph>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step6}
                                                alt={Step6}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                Step 4
                                            </Paragraph>
                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679; Fill up all the information & upload all the document/pictures
                                            </Paragraph>
                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679;     Click the “Submit” button to submit
                                            </Paragraph>
                                        </div>
                                    </div>
                                </div>
                            </DialogContentText>
                        </React.Fragment>
                    }
                </div>
            </Dialog>
        </React.Fragment >
    )

}

export default Home;